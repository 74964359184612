<template>
  <div>
    <sign-up-modal v-if="hasNewSignupFlow"/>
    <MasterHeader />
    <div class="container">
      <!-- 1st row -->
      <div class="row mb-2">
        <div class="col-12">
          <router-link to="/collaboration/my" class="btn btn-sm btn-link"
            ><i class="fas fa-angle-left"></i> Go Back</router-link
          >
        </div>
      </div>
      <!-- 2nd row -->
      <Loader :times="2" v-if="!isLoaded" />
      <div class="widget" v-else>
        <div class="widget-splash splash-line"></div>
        <div class="widget-header">
          <h4>Thesis Topic</h4>
        </div>
        <div class="widget-body widget-suggestion">
          <div class="row">
            <!-- 1st column -->
            <div class="col-12 col-md-6 col-lg-8 main-body">
              <!-- nav menu -->
              <ol class="nav nav-pills">
                <li
                  class="nav-item"
                  v-for="(step, index) in steps"
                  :key="index"
                >
                  <a
                    :class="[
                      activeStep == index + 1 ? 'active' : 'disabled',
                      'nav-link',
                    ]"
                    @click="nextStep(index + 1)"
                    aria-current="page"
                  >
                    <span class="list-item-counter">{{ index + 1 }}</span
                    >{{ step.title }}</a
                  >
                </li>
              </ol>
              <!--content activeStep 1--->
              <div v-if="activeStep == 1">
                <p>
                  To get your thesis topic in front of companies you need to
                  fill out the following sections. Go through all 6 steps and
                  once it is all filled out you can send your thesis topic for
                  review with the Excelerate team. The review should not take
                  more than 48hours for us to complete.
                </p>
                <div
                  class="embed-responsive embed-responsive-21by9 col-12 col-md-8 mt-5 mx-auto"
                >
                  <iframe
                    src="https://player.vimeo.com/video/600589215?h=2e1c172b40&color=ff0179&title=0&byline=0&portrait=0"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <!-- content -->
                <div class="my-4 form-group">
                  <label>Collaboration title</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Your thesis title"
                    v-model="collaborationDetails.title"
                    :class="{
                      'limit-exceeded': titleCharacters > 200,
                    }"
                    autofocus
                    required
                  />
                  <div>
                    <p class="tip">
                      <strong>Tip:</strong> The title is important, but remember
                      you can always change it later and this does not have to
                      be identical to your actual thesis title. Think of it as a
                      sales title.
                    </p>
                    <div class="charactor-counter">
                      Characters {{ titleCharacters }}/200
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Subtitle</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="The subtitle"
                    v-model="collaborationDetails.subtitle"
                    :class="{
                      'limit-exceeded': subTitleCharacters > 300,
                    }"
                    required
                  />
                  <div class="charactor-counter">
                    Characters {{ subTitleCharacters }}/300
                  </div>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <!-- TinyMCE editor -->
                  <div
                    :class="{
                      'limit-exceeded': mainContentCharacters > 5000,
                    }"
                  >
                    <tinymce
                      v-model="collaborationDetails.content"
                      key="1"
                    ></tinymce>
                  </div>
                  <div>
                    <p class="tip">
                      <strong>Tip: </strong>Remember, if you write too narrowly
                      on a subject you might limit yourself to a very small
                      group of companies if any. Writing too broad might make it
                      too vague. Finding the right balance is crucial.
                    </p>
                    <div class="charactor-counter">
                      Characters {{ mainContentCharacters }}/5000
                    </div>
                  </div>
                </div>
                <!-- /row -->
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <!-- Content column-->
              <div class="col-12 consult-block">
                <h4 class="consult-header pt-2">
                  Speak with an Excelerate Expert to kickstart your journey
                </h4>
                <p class="">
                  Book an Excelerate Expert for an online consultation to take
                  advantage of all the benefits and opportunities on Excelerate.
                  <br />
                  <br />
                  Use this opportunity to get advice on how to get started and
                  stand out from the crowd, how to attract the right companies
                  for the type of project collaboration you seek, and how to use
                  Excelerate to kickstart your career, and much more.
                </p>
                <div class="text-center">
                  <button
                    class="btn pink-button-consult px-5"
                    onclick="Calendly.initPopupWidget({url: 'https:\/\/calendly.com/excelerate-onboarding/thesis-supervision'});return false;"
                  >
                    Book now
                  </button>
                </div>
              </div>
              <div v-if="activeStep == 1">
                <div class="form-group">
                  <div class="info-title mb-2" v-if="user.institution">
                    <strong>University: </strong> {{ user.institution.name }}
                  </div>
                  <div class="info-title mb-2" v-show="user.faculty">
                    <strong>Faculty: </strong> {{ user.faculty }}
                  </div>
                  <div class="info-title mb-2">
                    <strong>Degree: </strong> {{ user.area }}
                  </div>

                  <label>Collaboration semester</label>
                  <select
                    class="form-control mb-4"
                    v-model="collaborationDetails.targetSemester"
                    required
                    @change="handleDates"
                  >
                    <option
                      v-for="(semester, index) in semesters"
                      :key="index"
                      :value="semester.value"
                    >
                      {{ semester.value }}
                    </option>
                  </select>

                  <div class="form-group">
                    <label for>What languages do you speak?</label>
                    <div>
                      <div
                        class="form-check form-check-inline"
                        v-for="(language, index) in languages"
                        :key="index"
                      >
                        <label class="control control-checkbox" :for="language">
                          {{ language }}
                          <input
                            type="checkbox"
                            :id="language"
                            :value="language"
                            v-model="collaborationDetails.languages"
                          />
                          <div class="control_indicator"></div>
                        </label>

                        <!-- <input
                                class="form-check-input"
                                type="checkbox"
                                :id="language"
                                :value="language"
                                v-model="collaborationDetails.languages"
                              />
                              <label class="form-check-label" :for="language">{{
                                language
                              }}</label> -->
                      </div>
                    </div>
                  </div>

                  <!-- <div v-else class="invite-member">
                    <label>Invite your student group members</label>
                    <input
                      v-for="(number,
                      index) in 1"
                      :key="index"
                      type="email"
                      :placeholder="
                        'Enter email for member#' + (number + 1) + ''
                      "
                      v-model="collaborationDetails.studentEmails['student' + number]"
                      class="form-control mb-2"
                    />
                    <p class="small-text">
                      student group members will receive a mail with a link to
                      join the group.
                    </p>

                  </div> -->
                  <div class="mb-2">
                    <label for="first-startdate"
                      ><span class="color-first">First</span> possible
                      collaboration start date</label
                    >
                    <input
                      type="date"
                      placeholder="enter date"
                      class="form-control"
                      id="first-startdate"
                      name="startdate-first"
                      v-model="collaborationDetails.firstStartDate"
                      required
                    />
                  </div>
                  <div class="mb-2">
                    <label for="last-startdate"
                      ><span class="color-first">Last</span> possible
                      collaboration start date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      id="last-startdate"
                      value="startdate-last"
                      v-model="collaborationDetails.lastStartDate"
                      required
                    />
                  </div>
                  <div class="mb-2">
                    <label for="hand-in-date">Thesis hand-in date</label>
                    <input
                      type="date"
                      class="form-control"
                      id="hand-in-date"
                      value="thesisHandInDate"
                      v-model="collaborationDetails.thesisHandInDate"
                      required
                    />
                  </div>
                </div>
                <!--  error-->
                <div class="alert alert-danger mt-2" v-if="inviteError">
                  {{ inviteError }}
                </div>
                <!-- end -->
                <div class="form-group">
                  <!-- <button
                    @click="activeStep++"
                    class="btn btn-primary btn-lg btn-block"
                    v-if="inReview"
                  >
                    Next
                  </button> -->

                  <!-- :class="{
                      disabled:
                        isTitleCharactersLimitExceeded ||
                        isSubTitleCharactersLimitExceeded ||
                        isMainContentCharactersLimitExceeded,
                    }" -->
                  <button
                    @click="nextStep(2)"
                    class="btn btn-primary btn-lg btn-block"
                  >
                    Save and next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row end -->

        <!-- 2nd column -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MasterHeader from '@/components/MasterHeader.vue';
import { db } from '../../firebase';
import json_semesters from '@/json/semesters.json';
// import Editor from "@tinymce/tinymce-vue";
import tinymce from '@/components/Editor.vue';
import Loader from '../../components/Loader.vue';
import SignUpModal from '../../components/signUpModal.vue';

// import { mapState } from 'vuex'
export default {
  data() {
    return {
      // invitations
      inviteError: '',
      inviteMessage: '',
      isNewCollaboration: true,
      collaborationDetails: {
        isNew: false,
        title: '',
        subtitle: '',
        content: '',
        // methodology: "",
        value: '',
        motivation: '',
        whyus: '',
        pitch: '',
        languages: [],
        numberOfStudents: 0,
        members: [],
        firstStartDate: '',
        lastStartDate: '',
        thesisHandInDate: '',
        studentEmails: {},
        targetSemester: '',
        stepCompleted: 0,
        keywords: [],
      },
      isModalOpen: false,

      // titleCharacters: 0,
      // subTitleCharacters: 0,
      // mainContentCharacters: 0,
      // isTitleCharactersLimitExceeded: false,
      // isSubTitleCharactersLimitExceeded: false,
      // isMainContentCharactersLimitExceeded: false,
      keyWords: [],
      // newCollabId:null,
      steps: [
        {
          title: 'Content',
          isActive: true,
        },
        // {
        //   title: "Methodology",
        //   isActive: false,
        // },
        {
          title: 'Value',
          isActive: false,
        },
        {
          title: 'Motivation',
          isActive: false,
        },
        {
          title: 'Why us',
          isActive: false,
        },
        {
          title: 'Preview',
          isActive: false,
        },
      ],
      activeStep: 1,
      languages: [
        'Danish',
        'Swedish',
        'Norwegian',
        'English',
        'German',
        'Spanish',
        'Other',
      ],
      numberOfStudents: [
        { name: 'Select', value: '0' },
        { name: '1 (just me)', value: '1' },
        { name: '2', value: '2' },
        { name: '3', value: '3' },
        { name: '4', value: '4' },
        { name: '5', value: '5' },
        { name: '6', value: '6' },
        { name: '7', value: '7' },
        { name: '8', value: '8' },
        { name: '9', value: '9' },
        { name: '10', value: '10' },
      ],
      semesters: json_semesters,
    };
  },
  components: {
    MasterHeader,
    tinymce,
    Loader,
    SignUpModal,
  },
  // firestore() {
  //   return {
  //     collaborationDetails: db
  //       .collection("collaborations")
  //       .doc(this.$route.params.id),
  //   };
  // },
  computed: {
    ...mapState(['user']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    getId: function () {
      return this.$store.state.collaboration.newCollabId;
    },
    isLoaded: function () {
      let res = false;
      var vm = this;
      if (typeof this.user.graduatingSemester != 'undefined') {
        if (vm.collaborationDetails.targetSemester == '') {
          vm.collaborationDetails.targetSemester = this.user.graduatingSemester;
        }

        res = true;
        this.handleDates();
      }
      return res;
    },
    isSubmitAllowed: function () {
      let res = false;
      let step = this.activeStep;
      switch (step) {
        case 1:
          if (
            this.titleCharacters == 0 ||
            this.subTitleCharacters == 0 ||
            this.mainContentCharacters == 0 ||
            this.collaborationDetails.languages.length == 0 ||
            this.collaborationDetails.firstStartDate == '' ||
            this.collaborationDetails.lastStartDate == '' ||
            this.collaborationDetails.thesisHandInDate == '' ||
            this.collaborationDetails.targetSemester == '' ||
            this.titleCharacters > 200 ||
            this.subTitleCharacters > 300 ||
            this.mainContentCharacters > 5000
          ) {
            res = false;
          } else {
            res = true;
          }
          break;

        default:
          break;
      }
      return res;
    },
    titleCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.title != 'undefined') {
        res = this.collaborationDetails.title.length;
      }
      return res;
    },
    subTitleCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.subtitle != 'undefined') {
        res = this.collaborationDetails.subtitle.length;
      }
      return res;
    },
    mainContentCharacters: function () {
      var res = 0;
      if (typeof this.collaborationDetails.content != 'undefined') {
        res = this.htmlToText(this.collaborationDetails.content).length;
      }
      return res;
    },
  },
  methods: {
    handleDates: function () {
      // if(this.collaborationDetails.firstStartDate != '')
      let semester = this.collaborationDetails.targetSemester;
      if (semester == 'Fall 2021') {
        this.collaborationDetails.firstStartDate = '2021-09-01';
        this.collaborationDetails.lastStartDate = '2021-11-01';
        this.collaborationDetails.thesisHandInDate = '2021-12-15';
      } else if (semester == 'Spring 2022') {
        this.collaborationDetails.firstStartDate = '2022-01-01';
        this.collaborationDetails.lastStartDate = '2022-03-01';
        this.collaborationDetails.thesisHandInDate = '2022-05-15';
      } else if (semester == 'Fall 2022') {
        this.collaborationDetails.firstStartDate = '2022-09-01';
        this.collaborationDetails.lastStartDate = '2022-11-01';
        this.collaborationDetails.thesisHandInDate = '2022-12-15';
      } else if (semester == 'Spring 2023') {
        this.collaborationDetails.firstStartDate = '2023-01-01';
        this.collaborationDetails.lastStartDate = '2023-03-01';
        this.collaborationDetails.thesisHandInDate = '2023-05-15';
      } else if (semester == 'Fall 2023') {
        this.collaborationDetails.firstStartDate = '2023-09-01';
        this.collaborationDetails.lastStartDate = '2023-11-01';
        this.collaborationDetails.thesisHandInDate = '2023-12-15';
      } else if (semester == 'Spring 2024') {
        this.collaborationDetails.firstStartDate = '2024-01-01';
        this.collaborationDetails.lastStartDate = '2024-03-01';
        this.collaborationDetails.thesisHandInDate = '2024-05-15';
      } else if (semester == 'Fall 2024') {
        this.collaborationDetails.firstStartDate = '2024-09-01';
        this.collaborationDetails.lastStartDate = '2024-11-01';
        this.collaborationDetails.thesisHandInDate = '2024-12-15';
      } else if (semester == 'Spring 2025') {
        this.collaborationDetails.firstStartDate = '2025-01-01';
        this.collaborationDetails.lastStartDate = '2025-03-01';
        this.collaborationDetails.thesisHandInDate = '2025-05-15';
      } else if (semester == 'Fall 2025') {
        this.collaborationDetails.firstStartDate = '2025-09-01';
        this.collaborationDetails.lastStartDate = '2025-11-01';
        this.collaborationDetails.thesisHandInDate = '2025-12-15';
      }
    },
    nextStep(step) {
      // sending mails to other studnets in collaboration before going to step 2(methodology)
      var vm = this;
      var isAlreadyInvited = false;

      vm.inviteError = '';
      vm.inviteMessage = '';
      const studentEmails = Object.values(
        vm.collaborationDetails.studentEmails
      );
      if (!this.isSubmitAllowed) {
        // return 'error'
        // alert('errorrrr')
        // console.log('eeeee')
        //! 0 - not completed, 1 completed and 2 - not submitted
        this.collaborationDetails.stepsStatus = {
          Content: 0, //content
          Value: 2, //value
          Motivation: 2, //motivation
          'Why us': 2, //why us
          Preview: 2, //preview
        };
      } else {
        this.collaborationDetails.stepsStatus = {
          Content: 1, //content
          Value: 2, //value
          Motivation: 2, //motivation
          'Why us': 2, //why us
          Preview: 2, //preview
        };
      }
      // this.mainContentCharacters = 0;
      // this.activeStep = step;
      // this.steps[step - 1].isActive = true;
      this.collaborationDetails.isNew = true;
      this.collaborationDetails.stepCompleted = this.activeStep;
      const data = {
        collaborationDetails: this.collaborationDetails,
        user: this.user,
      };
      this.$store.dispatch('addCollaboration', data);
      // }
      // }
    },

    dispatchCollaborationThesis() {
      const data = {
        collaborationDetails: this.collaborationDetails,
        user: this.user,
      };
      this.$store.dispatch('collaborationForReview', data);

      setTimeout(() => {
        console.log(this.getId);
        var vm = this;

        const mail = 'rajani@excelerate.careers';
        const templateData = {
          toEmail: mail,
          firstName: vm.user.firstName,
          lastName: vm.user.lastName,
          param1: vm.getId,
          param2: vm.collaborationDetails.title,
          template: 'Excelerate-Thesis submitted for review',
        };
        vm.$store.dispatch('sendMail', templateData);
        console.log(templateData);
      }, 1000);
    },

    validateCharectors(type, topic) {
      if (type === 'title') {
        this.titleCharacters = this.collaborationDetails.title.length;
        if (this.titleCharacters > 100) {
          this.isTitleCharactersLimitExceeded = true;
        } else {
          this.isTitleCharactersLimitExceeded = false;
        }
      } else if (type === 'subtitle') {
        this.subTitleCharacters = this.collaborationDetails.subtitle.length;
        if (this.subTitleCharacters > 50) {
          this.isSubTitleCharactersLimitExceeded = true;
        } else {
          this.isSubTitleCharactersLimitExceeded = false;
        }
      } else if (type === 'mainContent') {
        // console.log(topic, this.collaborationDetails[topic] )
        this.mainContentCharacters = this.htmlToText(
          this.collaborationDetails[topic]
        ).length;
        if (this.mainContentCharacters > 500) {
          this.isMainContentCharactersLimitExceeded = true;
        } else {
          this.isMainContentCharactersLimitExceeded = false;
        }
      }
    },
    htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },
  },

  // firestore() {
  //     return {
  //         proposal: db.collection("proposals").doc(this.$route.params.id),
  //     }
  // }
};
</script>
<style scoped>
.tip {
  display: inline-block;
  width: 75%;
  font-size: 10px;
  margin-bottom: 0;
  color: rgb(173, 173, 173);
}
.embed-responsive {
  height: 262px;
}
.embed-responsive iframe {
  width: 100%;
  height: 261px;
}
.consult-header {
  font-size: 24px;
  text-align: left;
  color: white;
}
</style>
<style>
.menu-container {
  border-top: 5px solid #6c2d88;
  border-radius: 5px;
}
.list-item-counter {
  font-size: 18px !important;
  color: white !important;
  margin-right: 1rem;
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
}
label {
  color: #333;
}
.form-group label {
  color: #333;
  font-weight: bold;
}
.invite-member label {
  color: #4c2f71;
}
.color-first {
  color: #4c2f71 !important;
}

button {
  width: auto;
  padding: 0.5rem 3rem;
}
.small-text {
  font-size: 12px;
}

.limit-exceeded {
  border: 1px solid red;
}

.main-body input.form-control {
  margin-bottom: 0.4rem;
  padding: 1.6rem 1rem;
  color: #666;
}
.disabled {
  color: grey;
}
.modal-box {
  color: black;
  font-size: 0.8rem;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.modal-box .splash-line {
  padding: 0.2rem;
  margin-top: -2px;
  border: 1px solid transparent;
  border-radius: 20px 20px 0 0;
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
}
.preview .btn.btn-primary {
  background-color: #bc1e73;
}
.keyword {
  /* color: #BC1E73;
  border: 1px solid #BC1E73;
  border-radius: 10px;
  margin: 0px 5px;
  padding: 2px 5px; */

  /* float: left; */
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 12px;
}

.form-group h4 strong {
  color: #333;
  font-weight: 600;
}

.form-group p {
  color: #555;
}
.review h5 {
  font-size: 24px;
  font-weight: 400;
}

.review h5 b {
  font-weight: 600;
}

.review h6 {
  font-weight: 400;
}

.review h6 b {
  font-weight: 600;
}

.review {
  color: #111;
}
</style>
<style>
.charactor-counter {
  float: right;
  width: 25%;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  color: rgb(173, 173, 173);
}
a.nav-link {
  /* color: #989898;
  padding: 0.5rem 2rem 0.5rem 0;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer; */
  padding: 0.5rem 2rem 0.5rem 0;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #d22d89;
  background: none;
  cursor: unset;
}
.tox-tinymce {
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

/* Checkbox Styles */
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e0dddd;
  border: 0px solid #000000;
  border-radius: 0px;
  pointer-events: none;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #da518a;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.9rem;
  margin-top: -0.9rem;
  background: #da518a;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}
.review .para p {
  font-size: 14px !important;
  color: #333 !important;
  font-weight: lighter !important;
}
.review strong,
.preview strong,
.form-group strong {
  font-weight: 600;
}
.preview .btn.btn-primary {
  background-color: #bc1e73;
  border-color: #bc1e73;
}
.preview .btn {
  font-size: 16px;
}
.preview .btn-primary.focus,
.btn-primary:focus,
.preview .btn-primary:not(:disabled):not(.disabled).active,
.preview .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #c7265e;
  border-color: #c7265e;
  box-shadow: 0 0 0 0.2rem rgb(206 73 119 / 29%);
}
.preview div {
  color: #111;
  font-size: 14px;
}
.modal-body {
  padding: 1.2rem 2rem;
}

.modal-content.modal-box {
  /* background: transparent linear-gradient(180deg, #F1F1F1 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
}
button.close {
  position: absolute;
  color: #fff;
  right: -34px;
  top: -39px;
  font-size: 42px;
  font-weight: 200;
}
.erow-cta {
  border-radius: 4px;
  padding: 10px 15px 10px;
  color: #fff;
  font-size: 14px;
  margin-bottom: -4px;
}
.erow-cta h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 2px;
}
.collab-toolbar .btn {
  color: #5a2871;
}
.invite-area h6 {
  font-size: 24px;
}

.invite-member p {
  color: #999;
}

/* Updated Styles From Client */
.widget .widget-header h4 {
  font-weight: 600;
  font-size: 22px;
}
body,
html {
  height: 100%;
  background: #fff;
  color: #361d49;
  font-size: 14px;
  background-color: #fff;
  background-image: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABkAAD/4QMsaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjYtYzE0OCA3OS4xNjQwMzYsIDIwMTkvMDgvMTMtMDE6MDY6NTcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMS4wIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDNTg4NDFCOEUyMkUxMUVBQjQzRUY0NUU5QjIwRTVENSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDNTg4NDFCOUUyMkUxMUVBQjQzRUY0NUU5QjIwRTVENSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkM1ODg0MUI2RTIyRTExRUFCNDNFRjQ1RTlCMjBFNUQ1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkM1ODg0MUI3RTIyRTExRUFCNDNFRjQ1RTlCMjBFNUQ1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgA/gABAwERAAIRAQMRAf/EAFgAAQEBAQAAAAAAAAAAAAAAAAMCBAoBAQAAAAAAAAAAAAAAAAAAAAAQAAIBAwUBAAAAAAAAAAAAAAABoWHREhFRkeETAhEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A7UAFwVYsBYCedY7AQBcFWLAJo9nwwHAXBViwC4fW0q4DAaAGw+dpdwG86x2A2H1tKuAwGgB9FsuEA2CrFgGwdJsA2j2fDAcB9FsuEAvnWOwEA0AXg6TYBQFwVYsBYCedY7AQD//Z);
  background-repeat: repeat-x;
  background-attachment: fixed;
}
.control {
  font-family: 'Poppins';
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  color: #333 !important;
  font-weight: 400 !important;
}
.form-group {
  margin-bottom: 1rem;
  margin-top: 5px;
}
.main-body {
  border-right: 1px solid lightgrey;
}
.widget .widget-header h4 small {
  color: #d22d89;
  font-size: 70%;
}
</style>
